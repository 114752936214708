import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ display: 'flex'}}>
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/communitiesSlides/seminarium-marana-tha-2019.jpg'} alt={'effatha-rozaniec-X-2019'}  style={{ padding: '5px 5px 30px 5px', margin: '10vw auto 0', width: '100%' }} />

            <p>KTO MOŻE WZIĄĆ UDZIAŁ?</p>
            <p>Każdy kto ma pytania o wiarę i Boga.</p>
            <p>Każdy kto pragnie w życiu czegoś więcej.</p>
            <p>Każdy kto szuka celu w życiu.</p>
            <p>Nieważne czy jesteś w Kościele od 50 lat, czy wcale. Nie ma znaczenia czy jesteś osobą wierzącą, czy nie. Czy masz lat 14, czy 70.</p>
            <p>Przyjdź i przekonaj się, że to czas dla Ciebie!</p>
            <p>CZYM JEST SEMINARIUM ODNOWY WIARY?</p>
            <p>To przypomnienie i PRZEŻYCIE podstawowych prawd wiary.</p>
            <p>– Po co wierzę?</p>
            <p>– Kim jest Bóg?</p>
            <p>– Czy On naprawdę mnie kocha, czy to tylko slogan?</p>
            <p>– Dlaczego tej Miłości w swoim życiu nie doświadczam?</p>
            <p>– W jaki sposób połączyć wiarę i codzienne życie?</p>
            <p>– Jak nawiązać kontakt z Bogiem?</p>
            <p>Seminarium Odnowy Wiary to czas zmian w Twojej relacji z Bogiem.</p>
            <p>Seminarium – wskazuje na pewien czas „szkolenia”, „nauki” dokładnie 7 cotygodniowych spotkań (w kazdy piatek od 18 października)</p>
            <p>Odnowy – czas odnowy Twojej wiary, żeby wiara nie była rutyną lub przyzwyczajeniem, powtarzaniem tych samych modlitw, regułek, wyuczonych zachowań religijnych, ale żywą relacją z Bogiem.</p>
            <p>Wiary – Można żyć inaczej!</p>
            <p>Można żyć piękniej!</p>
            <p>GDZIE?</p>
            <p>Aula Domu Miłosierdzia Otmuchów Rynek 38</p>
            
          </div>
        </div>
        <div className={classes.container} style={{ padding: '50px'}}></div>
      </div>      
    </Layout>
  );
}

